<div class="data-deletion-container">
  <img src="assets/images/Logotype-Horizontal_Groupe-Corso.png" alt="">
  <p>Cette page est dédiée aux utilisateurs de l'application <strong>Phi - Assistant Menuisier</strong>, développée par 
    <strong>Voyageur Marketing Innovation</strong>, qui souhaitent supprimer leurs données personnelles.
  </p>
  <h1>Comment demander la suppression de vos données</h1>
  <p>
    Pour demander la suppression de vos données personnelles, veuillez nous contacter par e-mail à l’adresse suivante :
    <a href="mailto:backoffice@groupecorso.fr">backoffice@groupecorso.fr</a>.
  </p>
  <p>
    Votre demande sera traitée dans un délai de <strong>4 à 5 jours ouvrés</strong> à compter de la réception de votre e-mail.
  </p>

  <h1>Suppression complète du compte utilisateur</h1>
  <p>
    Si vous souhaitez supprimer l’intégralité de votre compte, y compris toutes les données associées, veuillez accéder
    à votre espace personnel en ligne à l’adresse suivante :
    <a href="https://bo.maisondumenuisier.fr/bo/mon-compte" target="_blank" rel="noopener noreferrer">
      https://bo.maisondumenuisier.fr/bo/mon-compte</a>.
  </p>
  <p>
    Une fois connecté, faites défiler la page vers le bas et cliquez sur
    <strong>“Supprimer mon compte”</strong>.
  </p>
  <p>
    <strong>Attention :</strong> cette action est irréversible et rendra toutes les données de votre compte
    définitivement inaccessibles.
  </p>

  <h1>Contact</h1>
  <p>
    <strong>Développeur :</strong> Voyageur Marketing Innovation<br />
    <strong>Email de contact : </strong>
    <a href="mailto:backoffice@groupecorso.fr">backoffice@groupecorso.fr</a><br />
    <strong>Site web : </strong>
    <a href="https://bo.maisondumenuisier.fr" target="_blank" rel="noopener noreferrer">https://bo.maisondumenuisier.fr</a>
  </p>
</div>
